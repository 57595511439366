import './App.css'

function App() {
  return (
    <div style={{width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      initial setup.
    </div>
  );
}

export default App;
